import React from "react";

import InternalPageLayout from "../components/internal-page-layout";
import InternalPageContentCard from "../components/internal-page-content-card";
import TestimonialCard from "../components/testimonial-card";
import kitchen1 from "../images/kitchen-1.jpg";
import Gallery from "../components/gallery";
import { Link } from "gatsby";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <InternalPageLayout title="Residential Electrician" crumbs={["Services"]}>
      <SEO
        title="Residential Electrician in Baltimore County" />
      <InternalPageContentCard>
        <div className="w-full md:w-2/5 lg:w-1/2 mb-6 md:ml-6 md:float-right">
          <img src={kitchen1} className="max-w-full" alt="electrical kitchen services" />
        </div>
        <p className="mb-3">We've been helping homeowners in Baltimore County fix troubling problems and help bring their dream house to life. For over 30 years, we've been working with homeowners like you to fix the electrical issues in your home, install new energy efficient products, and wire their additions and new construction projects.</p>
        <p className="mb-6">With our careful attention to detail and professionalism, we're here to help you with your repairs, remodels, additions, and new construction. Contact us today for a free estimate.</p>

        <p className="font-bold">Some popular services we offer include:</p>

        <ul className="mt-2 list-disc ml-8">
          <li>Back-up generators</li>
          <li>Agricultural building electrical service and installation</li>
          <li>Custom built homes</li>
          <li>Recessed lighting</li>
          <li>Maintenance</li>
          <li>Home inspection repairs</li>
          <li><Link className="text-maroon hover:underline" to="/smart-home-installation">Lutron Lighting Control Systems</Link></li>
          <li>Small job installations (Receptacles, switches, paddle fans, light outlets, etc.)</li>
          <li>Security lighting</li>
          <li>Surge protectors</li>
          <li>Troubleshooting</li>
        </ul>
      </InternalPageContentCard>
      <InternalPageContentCard>
        <h3 className="text-gray-800 mb-1 font-semibold text-lg">Recent residential projects</h3>
        <p className="mb-12">Here are a few of our recent residential projects we've completed.</p>
        <Gallery gallery="residential" />
      </InternalPageContentCard>

      <div className="lg:flex">
        <div className="w-full mb-4 lg:w-1/2 lg:pr-4">
          <TestimonialCard
            author="Review on AngiesList"
            review="They were very pleasant to have in my house. Exceptional work from start to finish, very clean and knowledgeable of products and work. Best experience by far would highly recommend to anyone needing electrical work done."
          />
        </div>
        <div className="w-full mb-4 lg:w-1/2 lg:pl-4">
          <TestimonialCard
            author="Review on AngiesList"
            review="I'd give them an A+, that's why I use them. They're completely clean and leave things better than they found it. The price is good. They're efficient, quick, and very clean and tidy. They're very respectful of the home they're working in."
          />
        </div>
      </div>
    </InternalPageLayout>
  );
}

export default IndexPage;
